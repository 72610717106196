<template>
  <div class="user-profile">
    <section class="panel is-primary">
      <h1 class="title is-6 panel-heading mb-0">Статистика</h1>
      <div class="box">
        <div class="columns">
          <div class="column">
            <h6>Всего полисов:</h6>
            <p>
              {{ convertDigits(statistic.totalPolises) }}
            </p>
          </div>
          <div class="column">
            <h6>Сумма:</h6>
            <p>
              {{ convertDigits(statistic.totalAmount) }}
              <b-icon icon="ruble-sign" size="is-small"></b-icon>
            </p>
          </div>
          <div class="column">
            <h6>Общие комиссионное вознаграждение:</h6>
            <p>
              {{ convertDigits(statistic.totalKv) }}
              <b-icon icon="ruble-sign" size="is-small"></b-icon>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'UserStatistic',
  async created() {
    this.$store.commit('toggleLoader', true);
    await this.$store.dispatch('getme');
    await this.$store.dispatch('getStatistic');
    this.$store.commit('toggleLoader', false);
  },
  computed: {
    statistic() {
      return this.$store.state.statistic;
    },
  },
  methods: {
    convertDigits(val) {
      if (val !== undefined && val !== null) {
        return parseFloat(val).toLocaleString('ru-RU');
      }

      return '';
    },
  },
};
</script>

<style lang="scss">
.user-profile {
  h6 {
    font-weight: bold;
  }
}
</style>
